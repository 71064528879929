import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import useIsMobile from "hooks/useIsMobile";
import { styled } from "linaria/react";
import React from "react";

const ContactHeroContainer = styled(SectionContainer)`
  background: var(--gradient-light);
  padding-bottom: 1.25rem;
  @media (max-width: 600px) {
    padding: 5rem 0;
    padding-bottom: 0;
  }
`;

const SectionLeft = styled(SectionCol)`
  flex-direction: column;
  gap: 2.5rem;
  @media (max-width: 600px) {
    padding: 0 1.25rem;
    padding-bottom: 2.5rem;
  }
`;

const ContactHeroV2 = () => {
  const { isMobile } = useIsMobile(820);
  return (
    <ContactHeroContainer>
      <SectionInner>
        <SectionLeft>
          <TextContainer>
            <PreTitle>Custom Quote</PreTitle>
            <FancyPageTitle>
              Get a Custom Physical Security Quote
            </FancyPageTitle>
            <MainParagraph>
              Share your project with our team of experts to receive a
              comprehensive security consultation tailored to your needs,
              including smart cameras, IoT sensors, and alarms.
            </MainParagraph>
            <MainParagraph>
              With smarter physical security on a cloud-managed platform,
              Rhombus can help your business simplify operations and maximise
              safety, at scale.
            </MainParagraph>
          </TextContainer>
        </SectionLeft>
        <FlexEndColumn>
          <FormWrapper width={isMobile ? "100%" : "550px"}>
            <RhombusForm
              formId="89219072-bbdc-4c88-a130-21c4115a9839"
              useFormExpansion
              expandFormFieldIndex={2}
              groupSliceNumber={2}
              useSecondaryButton
            />
          </FormWrapper>
        </FlexEndColumn>
      </SectionInner>
    </ContactHeroContainer>
  );
};

export default ContactHeroV2;
