import ContactHeroV2 from "components/common/hero/ContactHeroV2";
import LogoSlider from "components/common/LogoSlider";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  PreTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const ContactPageV2 = () => {
  return (
    <>
      <ContactHeroV2 />
      <LogoSlider />
      <SectionContainer>
        <SectionInner style={{ justifyContent: "space-between" }}>
          <SectionCol>
            <StaticImage
              alt="g2 badges for Best Usability, Leader and Best Results for Spring 2023"
              loading="eager"
              src="./img/suite-dark-bg.png"
              placeholder="blurred"
              style={{ width: "100%" }}
            />
          </SectionCol>
          <FlexEndColumn>
            <TextContainer style={{ maxWidth: "500px" }}>
              <PreTitle color="var(--blue-500)">
                Curated, consultative solutions for your BUSINESS
              </PreTitle>
              <TitleMed>Your Trusted Security Partner</TitleMed>
              <MainParagraph>
                Best-in-class physical security is just the start. Rhombus is
                committed to building lasting partnerships, innovating products
                and services through customer feedback, and quickly rolling out
                new features that will keep you ahead of the curve.
              </MainParagraph>
            </TextContainer>
          </FlexEndColumn>
        </SectionInner>
      </SectionContainer>
      <IndustryLeadersCards
        color="var(--nuetral-100)"
        title="Industry Leaders Trust Rhombus as Their Security Solution"
        preTitle="CUSTOMER SUCCESS STORIES"
      />
    </>
  );
};

export default ContactPageV2;
