import ContactPageV2 from "components/contact/ContactPageV2";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import Helmet from "react-helmet";

export default function CustomQuoteLandingPageInternational() {
  return (
    <GlobalLayout color="var(--gradient-light)" landingPage>
      <Helmet>
        <title>Get Rhombus Price Quote - Commercial Video Surveillance </title>
        <meta
          name="description"
          content="Submit form for quote. Discounts available for volume orders, schools, & non-profits. For any questions, contact us at quote@rhombus.com or 916-907-7404."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ContactPageV2 />
    </GlobalLayout>
  );
}
